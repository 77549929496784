<template>
  <div>
    <div v-if="loading" class="flex sm12 lg12">
      <div class="text--center pb-4">
        <div class="flex-center spinner-box">
          <spinner/>
        </div>
      </div>
    </div>
    <div v-if="controlAreaData && !loading">
      <!-- Notifications -->
      <div class="mb-3">
        <va-notification
          :value="showNotification"
          :info="{level: notificationTypeLevel}"
          closeable
        >
          <va-badge :color="notificationType">{{ notificationType }}</va-badge>
          {{ $t('growing.recipe') + ' ' + storedRecipeNumber + ' ' +  notificationMessage}}
        </va-notification>
      </div>
      <div class="row">
        <div class="flex xs10">
          <h1 class="mb-3"> {{ controlAreaTitle }}</h1>
        </div>
      </div>

      <div class="cards">
        <div class="cards-container row d-flex wrap align--start">
          <!-- WORKORDER -->
          <work-order-overview-card
            :work-order-name="controlAreaData.work_order_name"
            :work-order-key="controlAreaData.work_order_key"
            :work-order-start-date="controlAreaData.work_order_start_date"
            :work-order-end-date="controlAreaData.work_order_end_date"
            :production-growing-recipe="controlAreaData.production_growing_recipe"
            :occupation="controlAreaData.occupation"
            @ontoggleEditControlArea="toggleEditControlArea"
          />

          <!-- RECIPE -->
          <recipe-overview-card
            :work-order-start-date="controlAreaData.work_order_start_date"
            :work-order-end-date="controlAreaData.work_order_end_date"
            :production-growing-recipe="controlAreaData.production_growing_recipe"
            @onChangeRecipe="onChangeRecipe"
          />

          <!-- IRRIGATION -->
          <irrigation-map
            :mapName="$t('irrigation.irrigationMap')"
            :irrigation-map-loading="irrigationMapLoading"
            :timeline-start-date="irrigationMapStartDate"
            :timeline-end-date="irrigationMapEndDate"
            :duration-days="irrigationMapDurationDays"
            :work-order-info="workOrderInfo"
            :irrigation-map="irrigationMap"
            :editable="false"
            :data-point="'day'"
            context="area"
          />
          <!-- LIGHT -->
          <data-card
            :id="controlAreaId"
            :data-name="'ppfd'"
            :data-icon="'at-icon l_ppfd'"
            :data-now-level="currentLightData.ppfd.level"
            :data-now-val="currentLightData.ppfd.val"
            :data-now-units="currentLightData.ppfd.units"
            :data-now-ref="currentLightData.ppfd.ref"
            :is-expandable="false"
          />
          <light-data-card
            :data-name="'setpoints'"
            :chart-data-setpoints="lightChartData.setpoints"
            :chart-options-setpoints="lightChartOptions.setpoints"
          />
          <light-data-card
            :data-name="'spectra'"
            :chart-data-spectrum="lightChartData.spectra"
            :chart-options-spectrum="lightChartOptions.spectra"
          />

          <div class="flex xs12"></div>
          <!-- AIR -->
          <data-card v-for="(dataName, index) in airDataNames" :key="'A' + index"
            :id="controlGroupId"
            :data-name="dataName"
            :data-icon="getAirDataIcon(dataName)"
            :data-now-level="currentAirData[dataName].level"
            :data-now-val="currentAirData[dataName].val"
            :data-now-units="currentAirData[dataName].units"
            :data-now-ref="currentAirData[dataName].ref"
            :data-last-hour-avg="airDataLastHour[dataName].avg"
            :data-last-hour-var="airDataLastHour[dataName].var"
            :data-last-hour-min="airDataLastHour[dataName].min"
            :data-last-hour-max="airDataLastHour[dataName].max"
            :data-last-hour-trend="airDataLastHour[dataName].trend"
            :chart-title="chartTitle"
            :chart-type="chartType"
            :chart-data="airChartData[dataName]"
            :chart-options="airChartOptions[dataName]"
            :is-expandable="true"
          />
        </div>
      </div>
    </div>

    <va-modal
      class="select-recipe-modal"
      v-model="showSelectRecipeModal"
      :noOutsideDismiss="false"
      :hideDefaultActions="true"
      size="large"
      ref="selectRecipeModal"
    >
      <template v-slot:header>
        <h6>{{ modalSubTitle }}</h6>
        <h4>{{ modalTitle }}</h4>
      </template>

      <template slot="actions">
        <div class="row">
          <div class="flex xs4">
          </div>
          <div class="flex xs8 text-right">
            <va-button @click="cancelChangeRecipe()" flat color="gray">{{ $t('modal.cancel') }}</va-button>
            <va-button @click="confirmChangeRecipe()" color="success">{{ $t('modal.confirm') }}</va-button>
          </div>
        </div>
      </template>

      <div class="form">
        <va-select
          :label="'Select Recipe'"
          v-model="selectedRecipe"
          textBy="name"
          :options="recipesList"
        />
      </div>

    </va-modal>

    <va-modal
      v-model="showEditControlAreaModal"
      :noOutsideDismiss="false"
      :hideDefaultActions="true"
      size="large"
      ref="editControlAreaModal"
    >
      <template v-slot:header>
        <h6>{{ $t('growing.editWorkorder') }}</h6>
      </template>

      <template>
        <form>
          <div class="flex xs12">
            <div class="row">
              <div class="flex md12 xs12 pb-3 pr-3">
                <va-input
                  v-model="controlAreaDataOccupationEdit"
                  :label="`${$t('growing.areaOccupation')}`"
                  type="number"
                />
              </div>

              <div class="flex md12 xs12 pb-3 pr-3">
                <va-input
                  v-model="controlAreaDataWorkOrderIdEdit"
                  :label="'Workorder id'"
                  type="number"
                />
              </div>

              <div class="flex md12 xs12 pb-3 pr-3">
                <va-input
                  v-model="controlAreaDataWorkOrderNameEdit"
                  :label="`${$t('growing.controlAreaEdition.workOrderName')}`"
                  type="string"
                />
              </div>

              <div class="flex md12 xs12 pb-3 pr-3">
                <va-input
                  v-model="controlAreaDataWorkOrderKeyEdit"
                  :label="`${$t('growing.controlAreaEdition.workOrderKey')}`"
                  type="string"
                />
              </div>

              <div class="flex md12 xs12 pb-3 pr-3">
                <at-date-picker
                  :label="`${$t('growing.controlAreaEdition.workOrderStartDate')}`"
                  v-model="controlAreaDataWorkOrderStartDateEdit"
                  :error="!!dateErrors.length"
                  :error-messages="dateErrors"
                />
              </div>

              <div class="flex md12 xs12 pb-3 pr-3">
                <at-date-picker
                  :label="`${$t('growing.controlAreaEdition.workOrderEndDate')}`"
                  v-model="controlAreaDataWorkOrderEndDateEdit"
                  :error="!!dateErrors.length"
                  :error-messages="dateErrors"
                />
              </div>
            </div>
          </div>
        </form>

        <div class="flex xs12 pl-5">
          <va-input
            v-model="saveControlString"
            type="string"
            :placeholder="$t('growing.controlAreaEdition.confirmSave')"
          />
        </div>
      </template>

      <template slot="actions">
        <div class="row">
          <div class="flex xs4 text-left">
            <va-checkbox
              :label="$t('growing.controlAreaEdition.setHarvested')"
              v-model="setHarvested"
            >
            </va-checkbox>
          </div>
          <div class="flex xs8 text-right">
            <va-button @click="closeModal()" flat color="gray">
              {{ $t('modal.cancel') }}
            </va-button>
            <va-button color="success" class="mr-0"
              :disabled="saveControlString !== 'SAVE'"
              @click="editControlArea()"
            >
              {{ $t('modal.confirm') }}
            </va-button>
          </div>
        </div>
      </template>

    </va-modal>
  </div>
</template>

<script>
import moment from 'moment-timezone'
import axios from 'axios'
import * as socketio from '../../../utilities/socketio'
import { hex2rgb } from '../../../services/vuestic-ui'
import { styleHelpers } from '@/services/atmosphere-ui'
import { chartLineSettings, chartBarSettings, getChartOptions } from '@/data/chartSettings'
import { getTimeFormat, getTimeDateFormat, getTimeStampDay, getLocalNowTimeDate, getUTCtimeString } from '@/services/atmosphere-ui/helpers/TimeHelpers'
import WorkOrderOverviewCard from '../../../services/atmosphere-ui/components/cards/at-workorder-overview-card/AtWorkOrderOverviewCard'
import RecipeOverviewCard from '../../../services/atmosphere-ui/components/cards/at-recipe-overview-card/AtRecipeOverviewCard'
import DataCard from '../../../services/atmosphere-ui/components/cards/at-data-card/AtDataCard'
import LightDataCard from '../../../services/atmosphere-ui/components/cards/at-light-data-card/AtLightDataCard'
import IrrigationMap from '@/services/atmosphere-ui/components/at-irrigation-map/AtIrrigationMap'
var crypto = require('crypto')

export default {
  name: 'growing-area-card-view',
  data () {
    return {
      loading: false,
      notFound: false,
      showSelectRecipeModal: false,
      recipesList: [],
      selectedRecipe: {},
      modalSubTitle: '',
      modalTitle: '',
      errorNotificationVisible: false,
      errorDetails: '',
      irrigationMapLoading: true,
      // airDataNames: ['temperature', 'relative_humidity', 'co2', 'dew_point', 'volume_flow', 'vpd'],
      airDataNames: ['temperature', 'relative_humidity', 'co2'],
      lightDataNames: ['light_state', 'ppfd', 'spectra', 'setpoints'],
      controlGroupData: null,
      controlAreaData: null,
      currentAirData: {},
      airDataLastHour: {},
      airDataDailyVariation: [],
      airDataHourlyVariation: [],
      airDataQuarterHourlyVariation: [],
      airChartData: {},
      airChartOptions: {},
      currentLightData: {},
      lightChartData: {},
      lightChartOptions: {},
      irrigationMap: [],
      start: null,
      end: null,
      selectedInvertal: { start: null, end: null, dataPoint: null },
      enableSlider: false,
      showEditControlAreaModal: false,
      setHarvested: false,
      showConfirmationModal: false,
      showControlAreaSaveNotification: false,
      controlAreaSaveNotificationTypeLevel: 0,
      controlAreaSaveNotificationType: '',
      controlAreaSaveNotificationMessage: '',
      controlAreaDataOccupationEdit: null,
      controlAreaDataWorkOrderIdEdit: null,
      controlAreaDataWorkOrderNameEdit: null,
      controlAreaDataWorkOrderKeyEdit: null,
      controlAreaDataWorkOrderStartDateEdit: null,
      controlAreaDataWorkOrderEndDateEdit: null,
      dateErrors: [],
      saveControlString: '',
    }
  },
  created () {
    this.$level.value = 'ground'
    this.$root.$on('selectedInvertal', this.onSelectedInterval)
  },
  mounted () {
    this.initializeView()
    this.getRecipesList()
  },
  computed: {
    activeFarm () {
      return this.$farmId ? this.$farmId : parseInt(process.env.VUE_APP_FARM_ID)
    },
    controlGroupId () {
      return this.controlAreaData ? this.controlAreaData.control_group_id : parseInt(this.$route.params.roomId)
    },
    controlAreaId () {
      return this.controlAreaData ? this.controlAreaData.id : parseInt(this.$route.params.areaId)
    },
    controlAreaTitle () {
      const name = this.controlAreaData.name ? this.controlAreaData.name : 'Area'
      const workOrderName = this.controlAreaData.work_order_name ? this.controlAreaData.work_order_name : ' Work order'
      return name + ' - ' + workOrderName
    },
    chartTitle () {
      if (this.selectedInvertal.dataPoint === 'day') {
        return this.$t('growing.charts.daily_variation')
      } else if (this.selectedInvertal.dataPoint === 'hour') {
        return this.$t('growing.charts.hourly_variation')
      } else if (this.selectedInvertal.dataPoint === '15min') {
        return this.$t('growing.charts.quater_hourly_variation')
      }
      return this.$('growing.charts.variation')
    },
    chartType () {
      if (this.selectedInvertal.dataPoint === 'day') {
        return 'vertical-bar'
      } else if (this.selectedInvertal.dataPoint === 'hour' || this.selectedInvertal.dataPoint === '15min') {
        return 'line'
      }
      return 'line'
    },
    irrigationMapStartDate () {
      if (this.controlAreaData.work_order_start_date) {
        return this.controlAreaData.work_order_start_date
      } else {
        return moment.utc().subtract(4, 'days').hours(0).minutes(0).format().slice(0, -1)
      }
    },
    irrigationMapEndDate () {
      if (this.controlAreaData.work_order_end_date) {
        return this.controlAreaData.work_order_end_date
      } else {
        return moment.utc().format().slice(0, -1)
      }
    },
    irrigationMapDurationDays () {
      return moment(this.irrigationMapEndDate).diff(this.irrigationMapStartDate, 'days') + 1
    },
    workOrderInfo () {
      let info = []
      if (this.controlAreaData) {
        info = [
          {
            id: this.controlAreaData.id,
            name: this.controlAreaData.name,
            work_order_id: this.controlAreaData.work_order_id,
            work_order_key: this.controlAreaData.work_order_key,
            work_order_name: this.controlAreaData.work_order_name,
            work_order_start_date: this.controlAreaData.work_order_start_date,
            work_order_end_date: this.controlAreaData.work_order_end_date,
            production_growing_recipe: this.controlAreaData.production_growing_recipe,
          },
        ]
      }

      return { [this.controlGroupId]: info }
    },
    showNotification () {
      return this.$store.getters.getSaveRecipe.showState
    },
    notificationType () {
      return this.$store.getters.getSaveRecipe.success ? 'success' : 'danger'
    },
    notificationTypeLevel () {
      return this.$store.getters.getSaveRecipe.success ? 0 : 3
    },
    notificationMessage () {
      const message = this.$store.getters.getSaveRecipe.message
      return this.$t(`growing.recipeEdition.${message}`)
    },
    storedRecipeNumber () {
      return this.$store.getters.getSaveRecipe.recipeNumber
    },
    formReady () {
      return !this.dateErrors.length
    },
    computedClass () {
      return {
        'va-card--danger': this.$status.value === 'danger', // change to warning from ros
      }
    },
  },
  methods: {
    getLineChartOptions (dataname, min, max) {
      return {
        ...getChartOptions('Time'),
        scales: {
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: this.$t(`data.${dataname}`),
            },
            ticks: {
              suggestedMin: Math.floor(min),
              suggestedMax: Math.ceil(max + max * 0.05),
            },
          }],
          xAxes: [{
            scaleLabel: {
              display: true,
              labelString: this.$t('timeData.time') + ' (' + moment().tz(this.$farmTimezone).zoneAbbr() + ')',
            },
          }],
        },
      }
    },
    getBarChartOptions (dataname) {
      return {
        ...getChartOptions('Day'),
        scales: {
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: this.$t(`data.${dataname}`),
            },
            ticks: {
              suggestedMin: 0,
              suggestedMax: 1,
            },
          }],
          xAxes: [{
            scaleLabel: {
              display: false,
              labelString: '',
            },
          }],
        },
      }
    },
    initializeView () {
      this.loading = true
      socketio.initialiseSocket()
      socketio.socket.emit('get_one_control_group', this.activeFarm, this.controlGroupId, { per_page: 15 })
      socketio.socket.once('get_one_control_group_response', data => {
        const parsed = JSON.parse(data)
        if (parsed.status >= 400 && parsed.status < 500) {
          console.log(parsed.message)
          this.notFound = true
        } else {
          if (parsed.data.id === this.controlGroupId) {
            this.controlGroupData = parsed.data.data
            this.controlAreaData = this.controlGroupData.control_areas.find(controlArea => controlArea.id === this.controlAreaId)

            if (this.controlAreaData.id === this.controlAreaId) {
              this.$store.commit('setTimelineMinDate', this.controlAreaData.work_order_start_date)

              this.selectedInvertal = {
                start: new Date(getLocalNowTimeDate().getTime() - (24 * 60 * 60 * 1000) * 1),
                end: getLocalNowTimeDate(),
                dataPoint: 'hour',
              }
              this.onSelectedInterval(this.selectedInvertal)
              this.loading = false
              this.initializeDataCards()
            } else {
              this.notFound = true
            }
          }
        }
      })
    },
    initializeDataCards () {
      this.controlAreaDataOccupationEdit = this.controlAreaData.occupation
      this.controlAreaDataWorkOrderIdEdit = this.controlAreaData.work_order_id
      this.controlAreaDataWorkOrderNameEdit = this.controlAreaData.work_order_name
      this.controlAreaDataWorkOrderKeyEdit = this.controlAreaData.work_order_key

      this.controlAreaDataWorkOrderStartDateEdit = this.controlAreaData.work_order_start_date ? moment(this.controlAreaData.work_order_start_date).format('YYYY-MM-DD') : null
      this.controlAreaDataWorkOrderEndDateEdit = this.controlAreaData.work_order_end_date ? moment(this.controlAreaData.work_order_end_date).format('YYYY-MM-DD') : null

      for (let i = 0; i < this.airDataNames.length; i++) {
        this.currentAirData = {
          ...this.currentAirData,
          [this.airDataNames[i]]: {},
        }
        this.airDataLastHour = {
          ...this.airDataLastHour,
          [this.airDataNames[i]]: {},
        }
        this.airChartData = {
          ...this.airChartData,
          [this.airDataNames[i]]: {},
        }
        this.airChartOptions = {
          ...this.airChartOptions,
          [this.airDataNames[i]]: {
            ...getChartOptions(''),
            scales: {
              yAxes: [{
                scaleLabel: {
                  display: true,
                  labelString: this.$t(`data.${this.airDataNames[i]}`),
                },
                ticks: {
                  suggestedMin: 0,
                  suggestedMax: 0,
                },
              }],
              xAxes: [{
                scaleLabel: {
                  display: true,
                  labelString: this.$t('timeData.time') + ' (' + moment().tz(this.$farmTimezone).zoneAbbr() + ')',
                },
              }],
            },
          },
        }
      }
      for (let i = 0; i < this.lightDataNames.length; i++) {
        this.currentLightData = {
          ...this.currentLightData,
          [this.lightDataNames[i]]: {},
        }
        this.lightChartData = {
          ...this.lightChartData,
          [this.lightDataNames[i]]: {},
        }
      }
      this.lightChartOptions = {
        spectra: {
          legend: {
            display: false,
          },
          scales: {
            xAxes: [{
              scaleLabel: {
                display: true,
                labelString: this.$t('lightData.wavelength') + ' (nm)',
              },
              gridLines: {
              },
            }],
            yAxes: [{
              scaleLabel: {
                display: true,
                labelString: this.$t('lightData.ppfd') + ' (μmol/m²/s)',
              },
              ticks: {
              },
              gridLines: {
              },
            }],
          },
        },
        setpoints: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
          scales: {
            xAxes: [{
              scaleLabel: {
                display: true,
                labelString: this.$t('lightData.percentage') + ' (%)',
              },
              ticks: {
                max: 100,
              },
              stacked: true,
              gridLines: {
                display: true,
              },
            }],
            yAxes: [{
              stacked: true,
              gridLines: {
                display: false,
              },
            }],
          },
        },
      }
      this.getIrrigationMap(this.irrigationMapStartDate, this.irrigationMapEndDate)
      this.listenIrrigationMapUpdate()
      this.listenAirCurrentData()
      this.listenLightCurrentData()

      this.listenAirHourlyDataUpdate()
    },
    listenAirCurrentData () {
      socketio.initialiseSocket()
      socketio.socket.emit('subscribe_to_context', [`control_group_${this.controlGroupId}`])
      socketio.socket.on('control_group_air_conditions_current_update', data => {
        const parsed = JSON.parse(data).data
        const parsedData = parsed.data
        for (let i = 0; i < this.airDataNames.length; i++) {
          this.currentAirData[this.airDataNames[i]] = parsedData[this.airDataNames[i]]
        }
      })
    },
    getAirDailyData (start, end) {
      socketio.socket.emit('get_control_group_air_conditions_daily', this.controlGroupId, { from: start, to: end })
      socketio.socket.on('get_control_group_air_conditions_daily_response', data => {
        const parsed = JSON.parse(data)
        if (parsed.status_code >= 400 && parsed.status_code > 500) {
          console.log('[ERROR (HOURLY conditions response)] ' + parsed.message.error)
        } else {
          const parsedData = parsed.data
          if (parsedData.total > 0) {
            this.airDataDailyVariation.splice(0)
            for (let i = 0; i < parsedData.data.length; i++) {
              this.airDataDailyVariation.push(parsedData.data[i])
            }
            this.getChartAirData()
          }
        }
      })
      this.getChartAirData()
    },
    listenAirHourlyDataUpdate () {
      socketio.socket.on('control_group_air_conditions_hourly_update', data => {
        const parsed = JSON.parse(data).data
        const parsedData = parsed.data
        for (let i = 0; i < this.airDataNames.length; i++) {
          this.airDataLastHour[this.airDataNames[i]] = parsedData[this.airDataNames[i]]
        }
        this.getChartAirData()
      })
    },
    getAirHourlyData (start, end) {
      socketio.socket.emit('get_control_group_air_conditions_hourly', this.controlGroupId, { from: start, to: end })
      socketio.socket.on('get_control_group_air_conditions_hourly_response', data => {
        const parsed = JSON.parse(data)
        if (parsed.status_code >= 400 && parsed.status_code > 500) {
          console.log('[ERROR (HOURLY conditions response)] ' + parsed.message.error)
        } else {
          const parsedData = parsed.data
          if (parsedData.total > 0) {
            this.airDataHourlyVariation.splice(0)
            const diff = (new Date() - new Date(end + 'Z')) / (60 * 60 * 1000)
            if (Math.floor(diff) < 2) {
              this.airDataLastHour = parsedData.data[parsedData.total - 1]
            }
            for (let i = 0; i < parsedData.data.length; i++) {
              this.airDataHourlyVariation.push(parsedData.data[i])
            }
            this.getChartAirData()
          }
        }
      })
    },
    getAirQuarterHourlyData (start, end) {
      socketio.socket.emit('get_control_group_air_conditions_quarter_hourly', this.controlGroupId, { from: start, to: end })
      socketio.socket.on('get_control_group_air_conditions_quarter_hourly_response', data => {
        const parsed = JSON.parse(data)
        if (parsed.status_code >= 400 && parsed.status_code > 500) {
          console.log('[ERROR (HOURLY conditions response)] ' + parsed.message.error)
        } else {
          const parsedData = parsed.data
          if (parsedData.total > 0) {
            this.airDataQuarterHourlyVariation.splice(0)
            for (let i = 0; i < parsedData.data.length; i++) {
              this.airDataQuarterHourlyVariation.push(parsedData.data[i])
            }
            this.getChartAirData()
          }
        }
      })
    },
    listenLightCurrentData () {
      socketio.initialiseSocket()
      socketio.socket.emit('subscribe_to_context', [`control_area_${this.controlAreaId}`])
      socketio.socket.on('control_area_light_conditions_current_update', data => {
        const parsed = JSON.parse(data)
        if (parsed.status_code >= 400 && parsed.status_code > 500) {
          console.log(parsed.message)
        } else {
          if (parsed.data.control_area_id === this.controlAreaId) {
            const parsedData = parsed.data.data
            for (let i = 0; i < this.lightDataNames.length; i++) {
              this.currentLightData[this.lightDataNames[i]] = parsedData[this.lightDataNames[i]]
            }
            this.getChartLightData()
          }
        }
      })
    },
    getChartAirData () {
      let values = []
      if (this.selectedInvertal.dataPoint === 'day') {
        values = this.airDataDailyVariation
      } else if (this.selectedInvertal.dataPoint === 'hour') {
        values = this.airDataHourlyVariation
      } else if (this.selectedInvertal.dataPoint === '15min') {
        values = this.airDataQuarterHourlyVariation
      }
      if (values.length > 0) {
        for (let i = 0; i < this.airDataNames.length; i++) {
          if (this.selectedInvertal.dataPoint === 'day') {
            const historyDay = values.map(element => {
              return (
                element[`${this.airDataNames[i]}_day`].avg
              )
            })
            const historyNight = values.map(element => {
              return (
                element[`${this.airDataNames[i]}_night`].avg
              )
            })

            this.airChartData[this.airDataNames[i]] = {
              labels: values.map(element => {
                return getTimeStampDay(element.time_stamp)
              }),
              datasets: [
                {
                  label: 'Night',
                  backgroundColor: hex2rgb(this.$themes.primary, 0.6).css,
                  ...chartBarSettings.night,
                  data: historyNight,
                },
                {
                  label: 'Day',
                  backgroundColor: hex2rgb(this.$themes.secondary, 0.6).css,
                  ...chartBarSettings.day,
                  data: historyDay,
                },
              ],
            }
            this.airChartOptions[this.airDataNames[i]] = this.getBarChartOptions(this.airDataNames[i])
          } else {
            const history = values.map(element => {
              return (
                {
                  x: getTimeFormat(moment(element.time_stamp + 'Z'), 'short', true),
                  y: element[this.airDataNames[i]].avg,
                  day: getTimeDateFormat(moment(element.time_stamp + 'Z'), 'short', true),
                }
              )
            })

            const maximums = values.map(element => {
              return (
                {
                  x: getTimeFormat(moment(element.time_stamp + 'Z'), 'short', true),
                  y: element[this.airDataNames[i]].max,
                  day: getTimeDateFormat(moment(element.time_stamp + 'Z'), 'short', true),
                }
              )
            })
            const reference = values.map(element => {
              return (
                {
                  x: getTimeFormat(moment(element.time_stamp + 'Z'), 'short', true),
                  y: element[this.airDataNames[i]].ref,
                  day: getTimeDateFormat(moment(element.time_stamp + 'Z'), 'short', true),
                }
              )
            })
            const minimums = values.map(element => {
              return (
                {
                  x: getTimeFormat(moment(element.time_stamp + 'Z'), 'short', true),
                  y: element[this.airDataNames[i]].min,
                  day: getTimeDateFormat(moment(element.time_stamp + 'Z'), 'short', true),
                }
              )
            })

            this.airChartData[this.airDataNames[i]] = {
              labels: values.map(element => {
                return this.selectedInvertal.dataPoint === 'day' ? getTimeStampDay(element.time_stamp) : getTimeFormat(moment(element.time_stamp + 'Z'), 'short', true)
              }),
              datasets: [
                {
                  label: 'Max',
                  ...chartLineSettings.max,
                  data: maximums,
                },
                {
                  label: 'Average',
                  ...chartLineSettings.average,
                  backgroundColor: hex2rgb(this.$themes.primary, 0.6).css,
                  data: history,
                },
                {
                  label: 'Reference',
                  ...chartLineSettings.reference,
                  data: reference,
                },
                {
                  label: 'Min',
                  ...chartLineSettings.min,
                  data: minimums,
                },
              ],
            }
            const min = Math.min.apply(Math, history.map(element => {
              return element.y
            }))

            const max = Math.max.apply(Math, history.map(element => {
              return element.y
            }))
            this.airChartOptions[this.airDataNames[i]] = this.getLineChartOptions(this.airDataNames[i], min, max)
          }
        }
      } else {
        for (let i = 0; i < this.airDataNames.length; i++) {
          this.airChartData[this.airDataNames[i]] = {
            labels: [],
            datasets: [],
          }
        }
      }
    },
    getChartLightData () {
      if (this.currentLightData) {
        this.lightChartData.spectra = {
          labels: this.currentLightData.spectra.wavelengths,
          datasets: [{
            label: 'Spectrum',
            data: this.currentLightData.spectra.val,
            backgroundColor: function (context) {
              const chart = context.chart
              const { ctx, chartArea } = chart

              if (!chartArea) {
                // This case happens on initial chart load
                return null
              }
              return styleHelpers.getGradient(ctx, chartArea)
            },
            borderColor: function (context) {
              const chart = context.chart
              const { ctx, chartArea } = chart

              if (!chartArea) {
                // This case happens on initial chart load
                return null
              }
              return styleHelpers.getGradient(ctx, chartArea)
            },
          }],
        }

        this.lightChartData.setpoints = {
          labels: [],
          datasets: [],
        }
        for (const [key, value] of Object.entries(this.currentLightData.setpoints)) {
          if (key !== 'units') {
            this.lightChartData.setpoints.datasets.push(
              {
                label: key,
                data: [Math.ceil(value)],
                backgroundColor: this.getColor(key),
                barPercentage: 1.0,
                categoryPercentage: 1.0,
              },
            )
          }
        }
      } else {
        this.lightChartData.spectra = {
          labels: [],
          datasets: [],
        }
        this.lightChartData.setpoints = {
          labels: [],
          datasets: [],
        }
      }
    },
    getColor (color) {
      if (color === 'blue') {
        return 'rgb(114, 138, 192)'
      } else if (color === 'green') {
        return 'rgb(122, 191, 130)'
      } else if (color === 'deep_red') {
        return 'rgb(218, 97, 91)'
      } else if (color === 'far_red') {
        return 'rgb(114, 38, 34)'
      } else {
        return 'rgb(128, 128, 128)'
      }
    },
    getAirDataIcon (dataName) {
      return styleHelpers.growingIcon('air', dataName)
    },
    getIrrigationMap (start, end) {
      this.irrigationMapLoading = true
      const controlAreaName = this.controlGroupData.name + ' ' + this.controlAreaData.name

      socketio.initialiseSocket()
      socketio.socket.emit('get_control_area_irrigation_map', this.activeFarm, this.controlGroupId, this.controlAreaId, controlAreaName, { from: start, to: end })
      socketio.socket.once('get_control_area_irrigation_map_response', data => {
        const parsed = JSON.parse(data)
        if (parsed.status >= 400 && parsed.status < 500) {
          console.log(parsed.message)
        } else {
          if (parsed.data.control_area_id === this.controlAreaId) {
            if (parsed.data.data[this.controlAreaId]) {
              this.irrigationMap = parsed.data
              this.irrigationMap.control_group_id = this.controlGroupId
              this.irrigationMapLoading = false
            }
          }
        }
      })
    },
    listenIrrigationMapUpdate () {
      socketio.initialiseSocket()
      socketio.socket.on('control_group_irrigation_map_update', data => {
        const parsed = JSON.parse(data)
        if (parsed.status >= 400 && parsed.status < 500) {
          console.log(parsed.message)
        } else {
          if (parsed.data.control_group_id === this.controlGroupId) {
            this.getIrrigationMap(this.irrigationMapStartDate, this.irrigationMapEndDate)
          }
        }
      })
    },
    onSelectedInterval: function (interval) {
      this.selectedInvertal = interval
      this.enableSlider = true

      if (this.selectedInvertal.dataPoint === 'day') {
        this.getAirDailyData(getUTCtimeString(this.selectedInvertal.start).slice(0, -1), getUTCtimeString(this.selectedInvertal.end).slice(0, -1))
      } else if (this.selectedInvertal.dataPoint === 'hour') {
        this.getAirHourlyData(getUTCtimeString(this.selectedInvertal.start).slice(0, -1), getUTCtimeString(this.selectedInvertal.end).slice(0, -1))
      } else if (this.selectedInvertal.dataPoint === '15min') {
        this.getAirQuarterHourlyData(getUTCtimeString(this.selectedInvertal.start).slice(0, -1), getUTCtimeString(this.selectedInvertal.end).slice(0, -1))
      }
    },
    getRecipesList () {
      axios.get(`${socketio.structuredApiUrl}/growing-recipes/`, { params: { per_page: 50 } })
        .then(response => {
          this.recipesList = response.data.data
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    onChangeRecipe (value) {
      if (value.productionGrowingRecipe.id === this.controlAreaData.production_growing_recipe.id) {
        this.showSelectRecipeModal = true
        this.modalSubTitle = 'Change recipe'
        this.selectedRecipe = {}
        this.getRecipesList()
      } else {
        this.$store.commit('setSaveRecipe', { showState: true, success: false, recipeNumber: this.controlAreaData.production_growing_recipe.id, message: 'saveErrorMessage' })
        this.errorDetails = 'errorProductionRecipeChange'
      }
    },
    cancelChangeRecipe () {
      this.selectedRecipe = {}
      this.$refs.selectRecipeModal.cancel()
    },
    encryptRecipe (recipe) {
      var algorithm = 'aes-256-ctr'
      var password = process.env.VUE_APP_RECIPE_DECENC
      var cipher = crypto.createCipheriv(algorithm, password, recipe.key)
      var crypted = cipher.update(JSON.stringify(recipe), 'utf8', 'hex')
      crypted += cipher.final('hex')
      return crypted
    },
    decryptRecipe (recipe) {
      var algorithm = 'aes-256-ctr'
      var password = process.env.VUE_APP_RECIPE_DECENC
      var decipher = crypto.createDecipheriv(algorithm, password, recipe.key)
      var dec = decipher.update(recipe.recipe, 'hex', 'utf8')
      dec += decipher.final('utf8')
      recipe = JSON.parse(dec)
      return recipe
    },
    confirmChangeRecipe () {
      const decriptedRecipe = this.decryptRecipe(this.selectedRecipe)
      decriptedRecipe.key = this.controlAreaData.production_growing_recipe.key

      const newRecipe = {
        key: this.controlAreaData.production_growing_recipe.key,
        name: this.selectedRecipe.name,
        recipe: this.encryptRecipe(decriptedRecipe),
        tags: this.controlAreaData.production_growing_recipe.tags,
      }
      const growingRecipeIdObject = {
        growing_recipe_id: this.selectedRecipe.id,
      }

      axios.put(`${socketio.structuredApiUrl}/growing-recipes/${this.controlAreaData.production_growing_recipe.id}`, newRecipe)
        .then(response => {
          if (response.status >= 200 && response.status < 300) {
            return axios.put(`${socketio.structuredApiUrl}/farms/1/control-groups/${this.controlAreaData.control_group_id}/control-areas/${this.controlAreaData.id}`, growingRecipeIdObject)
          } else {
            this.$store.commit('setSaveRecipe', { showState: true, success: false, recipeNumber: this.controlAreaData.production_growing_recipe.id, message: 'saveErrorMessage' })
          }
        })
        .then(response => {
          if (response.status >= 200 && response.status < 300) {
            this.$store.commit('setSaveRecipe', { showState: true, success: true, recipeNumber: this.controlAreaData.production_growing_recipe.id, message: 'saveSuccessfullyMessage' })
            this.initializeView()
          } else {
            this.$store.commit('setSaveRecipe', { showState: true, success: false, recipeNumber: this.controlAreaData.production_growing_recipe.id, message: 'saveErrorMessage' })
          }
        })
        .catch(error => {
          console.log(error)
          this.$store.commit('setSaveRecipe', { showState: true, success: false, recipeNumber: this.controlAreaData.production_growing_recipe.id, message: 'saveErrorMessage' })
        })

      this.$refs.selectRecipeModal.cancel()
    },
    toggleEditControlArea () {
      this.showEditControlAreaModal = !this.showEditControlAreaModal
    },
    editControlArea () {
      if (!this.setHarvested) {
        this.validateDates()
      }

      if (this.formReady) {
        const areaCopy = {}
        areaCopy.occupation = parseInt(this.controlAreaDataOccupationEdit)
        areaCopy.work_order_id = parseInt(this.controlAreaDataWorkOrderIdEdit)
        areaCopy.work_order_name = this.controlAreaDataWorkOrderNameEdit
        areaCopy.work_order_key = this.controlAreaDataWorkOrderKeyEdit

        areaCopy.work_order_start_date = !this.setHarvested ? moment.utc(this.controlAreaDataWorkOrderStartDateEdit).hours(6).format().slice(0, -1) : null
        areaCopy.work_order_end_date = !this.setHarvested ? moment.utc(this.controlAreaDataWorkOrderEndDateEdit).hours(20).format().slice(0, -1) : null

        if ('manual_update' in this.controlAreaData) {
          areaCopy.manual_update = true
        }

        axios.put(`${socketio.structuredApiUrl}/farms/1/control-groups/${this.controlAreaData.control_group_id}/control-areas/${this.controlAreaData.id}`, areaCopy)
          .then(response => {
            if (response.status >= 200 && response.status < 300) {
              this.closeModal()
              this.launchToast(this.$t('growing.controlAreaEdition.saveSuccessMessage'))
              this.initializeView()
            } else {
              this.closeModal()
              this.launchToast(this.$t('growing.controlAreaEdition.saveErrorMessage'), 'error')
            }
          })
          .catch(error => {
            console.log(error)
            this.closeModal()
            this.launchToast(this.$t('growing.controlAreaEdition.saveErrorMessage'), 'error')
          })
      }
    },
    validateDates () {
      const dateDiff = moment(this.controlAreaDataWorkOrderEndDateEdit).diff(moment(this.controlAreaDataWorkOrderStartDateEdit), 'days')
      this.dateErrors = dateDiff > 0 ? [] : [this.$t('growing.controlAreaEdition.workorderDateError')]
      if (this.dateErrors.length > 0) {
        this.saveControlString = ''
      }
    },
    clearFields () {
      this.controlAreaDataOccupationEdit = this.controlAreaData.occupation
      this.controlAreaDataWorkOrderIdEdit = this.controlAreaData.work_order_id
      this.controlAreaDataWorkOrderNameEdit = this.controlAreaData.work_order_name
      this.controlAreaDataWorkOrderKeyEdit = this.controlAreaData.work_order_key

      this.controlAreaDataWorkOrderStartDateEdit = this.controlAreaData.work_order_start_date ? moment(this.controlAreaData.work_order_start_date).format('YYYY-MM-DD') : null
      this.controlAreaDataWorkOrderEndDateEdit = this.controlAreaData.work_order_end_date ? moment(this.controlAreaData.work_order_end_date).format('YYYY-MM-DD') : null

      this.dateErrors = []
      this.saveControlString = ''
    },
    closeModal () {
      this.clearFields()
      this.$refs.editControlAreaModal.close()
    },
    launchToast (text, type) {
      this.showToast(
        text,
        {
          theme: 'none',
          type: type,
          position: 'top-center',
          duration: 8000,
          fullWidth: true,
        },
      )
    },
  },
  watch: {
    notFound () {
      if (this.notFound) {
        this.loading = false
        this.$router.push({ path: '/404/page-not-found' })
      }
    },
    setHarvested () {
      if (this.setHarvested) {
        this.controlAreaDataOccupationEdit = 0
        this.controlAreaDataWorkOrderIdEdit = 0
        this.controlAreaDataWorkOrderNameEdit = 'Empty'
        this.controlAreaDataWorkOrderKeyEdit = 'WO: NA'
        this.controlAreaDataWorkOrderStartDateEdit = null
        this.controlAreaDataWorkOrderEndDateEdit = null
      } else {
        this.controlAreaDataOccupationEdit = this.controlAreaData.occupation
        this.controlAreaDataWorkOrderIdEdit = this.controlAreaData.work_order_id
        this.controlAreaDataWorkOrderNameEdit = this.controlAreaData.work_order_name
        this.controlAreaDataWorkOrderKeyEdit = this.controlAreaData.work_order_key
        this.controlAreaDataWorkOrderStartDateEdit = moment(this.controlAreaData.work_order_start_date).format().slice(0, -1)
        this.controlAreaDataWorkOrderEndDateEdit = moment(this.controlAreaData.work_order_end_date).format().slice(0, -1)
      }
    },
  },
  components: {
    WorkOrderOverviewCard,
    RecipeOverviewCard,
    DataCard,
    LightDataCard,
    IrrigationMap,
  },
  beforeDestroy () {
    this.$root.$off('selectedInvertal', this.onSelectedInterval)
    socketio.socket.emit('unsubscribe_from_context', [`control_group_${this.controlGroupId}`])
    socketio.socket.emit('unsubscribe_from_context', [`control_area_${this.controlAreaId}`])
  },
}
</script>

<style lang='scss'>
.va-modal .va-modal__message .va-input__container__input {
  padding-bottom: 0 !important;
}
</style>
