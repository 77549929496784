<template>
  <div class="flex xs8">
    <va-card
      :title="$t('growing.workOrderDetails')"
      :style="computedStyle"
      :class="computedClass">

      <template slot="actions">
        <va-popover
          :message="`${$t('growing.editWorkorder')}`"
          :placement="'left'"
        >
          <va-button @click="toggleEditControlArea()" flat icon="at-icon l_edit1" :style="{'font-size': '2rem'}"></va-button>
        </va-popover>
      </template>

      <div class="row">
        <div class="flex xs12">
          <div class="row" >
            <!-- Work order info -->
            <div
              v-if="getProductImage() !== ''"
              class="flex xs5"
            >
              <div :style="{display: 'inline-block'}">
                <div class="row">
                  <div class="flex xs12 content__order">
                    <div class="content__order__image">
                      <va-icon :name="'at-icon l_bag'" :style="{visibility: 'hidden'}"/>
                      <img :src="getProductImage()" />
                    </div>
                    <div class="content__order__details">
                      <div class="content__order__details__titles">
                        <p class="content__order__details__title">{{ workOrderName ? workOrderName : ' - '}}</p>
                        <p class="content__order__details__subtitle">{{  $t(`growing.products`) }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-else class="flex xs5">
              <div :style="{display: 'inline-block'}">
                <div class="row">
                  <div class="flex xs12 content__order">
                    <div class="content__order__icon">
                      <va-icon :name="'at-icon l_bag'"/>
                    </div>
                    <div class="content__order__details">
                      <div class="content__order__details__titles">
                        <p class="content__order__details__title">{{  workOrderName ? workOrderName : ' - ' }}</p>
                        <p class="content__order__details__subtitle">{{  $t(`growing.products`) }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="flex xs5">
              <div :style="{display: 'inline-block'}">
                <div class="row">
                  <div class="flex xs12 content__order">
                    <div class="content__order__icon">
                      <va-icon :name="'at-icon l_barcode2'"/>
                    </div>
                    <div class="content__order__details">
                      <div class="content__order__details__titles">
                        <p class="content__order__details__title">{{ workOrderKey ? workOrderKey : ' - '}}</p>
                        <p class="content__order__details__subtitle">{{ $t(`growing.workOrder`) }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="flex xs2">
              <div class="row">
                <div class="flex xs12 content__order__occupation">
                  <div>
                    <div class="content__order__occupation__value">
                      <va-progress-circle :value="occupation">{{ occupation }}%</va-progress-circle>
                    </div>
                    <div class="content__order__occupation__subtitle">
                      <p>{{ $t(`growing.areaOccupation`) }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <!-- Growing Progress -->
        <div class="flex xs12 mt-2">
          <div class="row">
            <div class="flex xs3 content__order__progress" :style="{visibility: workOrderStartDate ? 'visible' : 'hidden'}">
              <div class="content__order__progress__titles">
                <p class="content__order__progress__title">{{ $t('growing.start') }}</p>
                <p class="content__order__progress__subtitle">{{ printTimeStamp(workOrderStartDate) }}</p>
              </div>
            </div>
            <div class="flex xs6 content__order__progress">
              <div class="content__order__progress__titles">
                <va-progress-bar v-model="growingProgress.percent">
                  <span class="content__order__progress__percentage" :style="{visibility: workOrderEndDate ? 'visible' : 'hidden'}">{{ Math.floor(growingProgress.percent) }}%</span>
                </va-progress-bar>
                <!-- <div class="content__order__progress__value">
                  <span v-if="growingProgress.daysTotal">{{ $t('growing.progress') }} {{ growingProgress.currentDay }}/{{ growingProgress.daysTotal }} {{ $t('timeData.days') }}</span>
                  <span v-else>{{ $t('growing.progress') }}</span>
                </div> -->
              </div>
            </div>
            <div class="flex xs3 content__order__progress" :style="{visibility: workOrderEndDate ? 'visible' : 'hidden'}">
              <div class="content__order__progress__titles">
                <p class="content__order__progress__title">{{ $t('growing.end') }}</p>
                <p class="content__order__progress__subtitle"> {{ printTimeStamp(workOrderEndDate) }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </va-card>

  </div>
</template>

<script>
import { getDateFormat } from '../../../../../services/atmosphere-ui/helpers/TimeHelpers'
import yummmixDelicato from '@/assets/imgs/product-bags/Bag_YumMIX_Delicato.png'
import yummmixOrientale from '@/assets/imgs/product-bags/Bag_YumMIX_Orientale.png'
import yummmixPiccante from '@/assets/imgs/product-bags/Bag_YumMIX_Piccante.png'
import basilicooh from '@/assets/imgs/product-bags/Bag_BasilicOOH.png'
import lattugood from '@/assets/imgs/product-bags/Bag_LattuGOOD.png'
import rucola from '@/assets/imgs/product-bags/Bag_RuCOOL.png'

export default {
  name: 'work-order-overview-card',
  data () {
    return {
      showInModal: false,
      growingProgress: {},
    }
  },
  props: {
    workOrderName: {
      type: String,
      default: '',
    },
    workOrderKey: {
      type: String,
      default: null,
    },
    workOrderStartDate: {
      type: String,
      default: null,
    },
    workOrderEndDate: {
      type: String,
      default: null,
    },
    occupation: {
      type: Number,
    },
  },
  created () {
    this.getGrowingProgress()
  },
  computed: {
    computedClass () {
      return {
        'va-card--danger': this.$status.value === 'danger',
      }
    },
    computedStyle () {
      const style = {
        height: '19rem',
        /* Update styles via JS Helpers (currently they are being changed in a global watcher) */
        // '--primary-color-hue': hex2hsl(this.$themes.primary).h,
        // '--primary-color': this.$themes.primary,

        /* Alternative: Update styles via JS Helpers */
        // background: cardGradient(this.$themes.primary, this.$status.value, this.$mode.value),
        // boxShadow: genShadow(this.$themes.primary, this.$status.value, this.$mode.value),
        // borderRadius: genRadius({ topLeft: '24px', bottomRight: '24px' }),
      }
      return style
    },
  },
  methods: {
    getProductImage () {
      const workorderName = this.workOrderName ? this.workOrderName.toLowerCase() : ''
      if (workorderName.includes('yummmix')) {
        if (workorderName.includes('delicato')) {
          return yummmixDelicato
        } else if (workorderName.includes('orientale')) {
          return yummmixOrientale
        } else if (workorderName.includes('piccante')) {
          return yummmixPiccante
        } else {
          return ''
        }
      } else if (workorderName.includes('basilicooh')) {
        return basilicooh
      } else if (workorderName.includes('lattugood')) {
        return lattugood
      } else if (workorderName.includes('rucola')) {
        return rucola
      }

      return ''
    },
    getGrowingProgress () {
      let daysTotal = 0
      let currentDay = -1
      let daysLeft = 0
      let percentage = 0

      if (this.workOrderStartDate === null || this.workOrderEndDate === null) {
        daysTotal = null
        currentDay = -1
        daysLeft = null
        percentage = 0
      } else {
        const start = new Date(this.workOrderStartDate + 'Z')
        start.setUTCHours(0, 0, 0)
        const end = new Date(this.workOrderEndDate + 'Z')
        end.setUTCHours(23, 59, 59)

        const secTotal = (end - start) / 1000 // s
        const secPassed = (new Date() - start) / 1000
        const secLeft = secTotal - secPassed

        if (secLeft < 0) { // already ended
          daysTotal = Math.floor(secTotal / (24 * 60 * 60))
          currentDay = daysTotal
          daysLeft = 0
          percentage = 100
        } else if (secPassed > secTotal) { // not started
          daysTotal = Math.floor(secTotal / (24 * 60 * 60))
          currentDay = -1
          daysLeft = 0
          percentage = 0
        } else { // growing now
          daysTotal = Math.floor(secTotal / (24 * 60 * 60))
          currentDay = Math.floor(secPassed / (24 * 60 * 60))
          daysLeft = daysTotal - currentDay
          percentage = Math.floor(secPassed / secTotal * 100)
        }
      }
      this.growingProgress = {
        daysTotal: daysTotal < 0 ? 0 : daysTotal,
        currentDay: currentDay,
        daysLeft: daysLeft,
        percent: percentage,
      }
    },
    printTimeStamp (timestampUTC) {
      return getDateFormat(timestampUTC + 'Z', 'long', true)
    },
    toggleEditControlArea () {
      this.$emit('ontoggleEditControlArea')
    },
  },
}
</script>

<style lang="scss">
.content__order {
  display: flex;
  min-height: 7rem;

  &__icon {
    font-size: pxtorem(30);
    display: flex;
    justify-content: flex-end;
    padding-top: 1.5rem;
    padding-right: 1rem;

    i {
      color: hsl(var(--primary-color-hue), 30%, 70%);
      font-size: pxtorem(46);
      margin-right: 0;
    }
  }

  &__image {
    font-size: pxtorem(40);
    display: flex;
    position: relative;
    justify-content: flex-end;
    padding-top: 1.5rem;
    padding-right: 1rem;
    padding-left: 2rem;

    img {
      position: absolute;
      height: 90%;
    }
  }

  &__details {
    display: flex;
    text-align: center;
    min-height: 7.05rem;

    &__titles {
      display: inline-block;
      align-self: end;
    }

    &__title {
      font-size: pxtorem(22);
      color: var(--primary-color);
    }

    &__subtitle {
      font-size: pxtorem(15);
      color: hsl(var(--primary-color-hue), 40%, 30%);
      padding-top: 0.5rem;
    }
  }

  &__occupation {
    min-height: 7rem;

    &__value {
      display: flex;
      justify-content: center;
    }

    &__subtitle {
      display: flex;
      justify-content: center;
      font-size: pxtorem(15);
      color: hsl(var(--primary-color-hue), 40%, 30%);
      padding-top: 0.5rem;
    }
  }

  &__progress__value {
    font-size: pxtorem(16);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    letter-spacing: pxtorem(0.25);
  }

  &__progress {
    display: flex;
    text-align: center;
    min-height: 6rem;

    &__titles {
      display: inline-block;
      align-self: end;

      .va-progress-bar {
        padding-bottom: 1rem;
      }
    }

    &__title {
      color: var(--primary-color);
      font-weight: 500;
    }

    &__subtitle,
    &__percentage {
      color: hsl(var(--primary-color-hue), 40%, 30%);
    }
  }
}

.va-card-table.recipes-card .va-card__body {
  padding: 0;
  overflow-y: hidden;
}

</style>
