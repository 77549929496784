<template>
  <div class="flex xs4">
    <va-card
      :title="$t(`lightData.${dataName}`)">
      <div class="row">
        <div class="flex xs12">
          <template v-if="dataName === 'setpoints'">
            <!-- <h6>{{ chartTitle }}</h6> -->
            <va-chart class="chart" ref="barChart" :data="chartDataSetpoints" :options="chartOptionsSetpoints" type="horizontal-bar"/>
          </template>
          <template v-if="dataName === 'spectra'">
            <!-- <h6>{{ chartTitle }}</h6> -->
            <va-chart class="chart" ref="lineChart" :data="chartDataSpectrum" :options="chartOptionsSpectrum" type="line"/>
          </template>
        </div>
      </div>
    </va-card>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'light-data-card',
  data () {
    return {
      lightData: {},
    }
  },
  props: {
    dataName: {
      type: String,
      default: '',
    },
    chartTitle: {
      type: String,
      default: 'Variation',
    },
    chartDataSetpoints: {
      type: Object,
      default: () => {
        return {
        }
      },
    },
    chartOptionsSetpoints: {
      type: Object,
      default: () => {
        return {
        }
      },
    },
    chartDataSpectrum: {
      type: Object,
      default: () => {
        return {
        }
      },
    },
    chartOptionsSpectrum: {
      type: Object,
      default: () => {
        return {
        }
      },
    },
  },
  computed: {
    computedClass () {
      return {
        'va-card--danger': this.$status.value === 'danger',
      }
    },
    computedStyle () {
      const style = {
        /* Update styles via JS Helpers (currently they are being changed in a global watcher) */
        // '--primary-color-hue': hex2hsl(this.$themes.primary).h,
        // '--primary-color': this.$themes.primary,

        /* Alternative: Update styles via JS Helpers */
        // background: cardGradient(this.$themes.primary, this.$status.value, this.$mode.value),
        // boxShadow: genShadow(this.$themes.primary, this.$status.value, this.$mode.value),
        // borderRadius: genRadius({ topLeft: '24px', bottomRight: '24px' }),
      }
      return style
    },
    language () {
      return Vue.i18n.locale()
    },
  },
  methods: {
  },
  watch: {
    'language' () {
      if (this.dataName === 'spectra') {
        this.chartOptionsSpectrum.scales.xAxes[0].scaleLabel.labelString = this.$t('lightData.wavelength') + ' (nm)'
        this.chartOptionsSpectrum.scales.yAxes[0].scaleLabel.labelString = this.$t('lightData.ppfd') + ' (μmol/m²/s)'
        this.$refs.lineChart.$refs.chart.chartOptions = this.chartOptionsSpectrum
        this.$refs.lineChart.$refs.chart.refresh()
      } else if (this.dataName === 'setpoints') {
        this.chartOptionsSetpoints.scales.xAxes[0].scaleLabel.labelString = this.$t('lightData.percentage') + ' (%)'
        this.$refs.barChart.$refs.chart.chartOptions = this.chartOptionsSetpoints
        this.$refs.barChart.$refs.chart.refresh()
      }
    },
  },
}
</script>

<style lang="scss">
.va-chart {
  max-height: 190px; // fix bug with charts and titles when using slots
}
</style>
