export const chartLineSettings = {
  max: {
    lineTension: 0,
    hidden: true,
    fill: false,
    borderDash: [5, 10],
    pointStyle: 'dash',
    pointRadius: 0,
    borderWidth: 1.5,
    backgroundColor: 'transparent',
    borderColor: '#D71A31',
  },
  min: {
    lineTension: 0,
    hidden: true,
    fill: false,
    borderDash: [10, 20],
    pointStyle: 'dash',
    pointRadius: 0,
    borderWidth: 1.5,
    backgroundColor: 'transparent',
    borderColor: '#F89300',
  },
  reference: {
    lineTension: 0,
    fill: false,
    pointStyle: 'line',
    pointRadius: 0,
    borderWidth: 1.5,
    backgroundColor: 'transparent',
    borderColor: '#165E6C',
  },
  average: {
    lineTension: 0,
    borderColor: 'transparent',
  },
}

export const chartBarSettings = {
  night: {
    borderColor: 'transparent',
  },
  day: {
    borderColor: 'transparent',
  },
}

export function getChartOptions (title) {
  return {
    responsive: true,
    tooltips: {
      titleFontFamily: "'Quicksand', sans-serif",
      bodyFontFamily: "'Montserrat', sans-serif",
      callbacks: {
        label: function (tooltipItem, data) {
          let label = data.datasets[tooltipItem.datasetIndex].label || ''
          if (label) {
            label += ': '
          }
          label += Math.round(tooltipItem.yLabel * 100) / 100
          return label
        },
        title: function (tooltipItem, data) {
          const title = data.datasets[tooltipItem[0].datasetIndex].data[tooltipItem[0].index].day
          return title
        },
      },
    },
  }
}
