<template>
  <div class="flex xs4">
    <va-card
      :title="$t('growing.recipe')"
      :style="computedStyle"
      :class="computedClass">

      <div class="row">
        <div class="flex xs12">
          <div class="row">
            <div class="flex xs12">
              <div>
                <div class="row">
                  <div class="flex xs12 content__recipe">
                    <div class="content__recipe__icon">
                      <va-icon :name="'at-icon l_recipes3'"/>
                    </div>
                    <div class="content__recipe__details">
                      <div class="content__recipe__details__titles">
                        <p class="content__recipe__details__title">{{ productionGrowingRecipe ? productionGrowingRecipe.name : '' }}</p>
                        <p class="content__recipe__details__subtitle">{{ $t('growing.recipe')}}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>

        <div class="flex xs12 content__recipe__buttons" v-if="getValidRole()">
          <div class="content__recipe__buttons__content">
            <div class="content__recipe__buttons__content__buttons">
              <va-button flat icon='at-icon l_eyeopen' @click="showInModal = true">
                {{ $t('growing.seeRecipe') }}
              </va-button>
              <va-button flat icon='at-icon l_edit1' :to="{ name: 'area-recipe-growing-edit', params: { roomId: getControlGroupId(), areaId: getAreaId(), recipeId: getGrowingRecipeId(), displayName: $t('growing.editRecipe') }}">
                {{ $t('growing.editRecipe') }}
              </va-button>
              <va-button flat icon='at-icon l_reload' @click="onChangeRecipe">
                {{ $t('growing.changeRecipe') }}
              </va-button>
            </div>
          </div>
        </div>
      </div>
    </va-card>

    <va-modal
      class="recipes-modal"
      v-model="showInModal"
      :noOutsideDismiss="false"
      :hideDefaultActions="true"
      size="large"
      ref="modal"
    >
      <at-recipe-timeline @cancel="cancelModal"
        :growing-recipe-encrypted="productionGrowingRecipe"
        :is-production-recipe="true"
        :current-day="growingProgress.currentDay"
        :is-in-modal="true"
        :editable="false"
      />
    </va-modal>
  </div>
</template>

<script>
import AtRecipeTimeline from '../../../../../services/atmosphere-ui/components/recipes/AtRecipeTimeline.vue'

export default {
  name: 'recipe-overview-card',
  data () {
    return {
      showInModal: false,
      growingProgress: {},
    }
  },
  props: {
    workOrderStartDate: {
      type: String,
      default: null,
    },
    workOrderEndDate: {
      type: String,
      default: null,
    },
    productionGrowingRecipe: {
      type: Object,
    },
  },
  computed: {
    computedClass () {
      return {
        'va-card--danger': this.$status.value === 'danger',
      }
    },
    computedStyle () {
      const style = {
        height: '19rem',
        /* Update styles via JS Helpers (currently they are being changed in a global watcher) */
        // '--primary-color-hue': hex2hsl(this.$themes.primary).h,
        // '--primary-color': this.$themes.primary,

        /* Alternative: Update styles via JS Helpers */
        // background: cardGradient(this.$themes.primary, this.$status.value, this.$mode.value),
        // boxShadow: genShadow(this.$themes.primary, this.$status.value, this.$mode.value),
        // borderRadius: genRadius({ topLeft: '24px', bottomRight: '24px' }),
      }
      return style
    },
  },
  mounted () {
    this.getGrowingProgress()
  },
  methods: {
    getControlGroupId () {
      return parseInt(this.$route.params.roomId)
    },
    getAreaId () {
      return parseInt(this.$route.params.areaId)
    },
    getGrowingRecipeId () {
      return this.productionGrowingRecipe.id
    },
    getGrowingProgress () {
      let daysTotal = 0
      let currentDay = -1
      let daysLeft = 0
      let percentage = 0

      if (this.workOrderStartDate === null || this.workOrderEndDate === null) {
        daysTotal = null
        currentDay = -1
        daysLeft = null
        percentage = 0
      } else {
        const start = new Date(this.workOrderStartDate + 'Z')
        start.setUTCHours(0, 0, 0)
        const end = new Date(this.workOrderEndDate + 'Z')
        end.setUTCHours(23, 59, 59)

        const secTotal = (end - start) / 1000 // s
        const secPassed = (new Date() - start) / 1000
        const secLeft = secTotal - secPassed

        if (secLeft < 0) { // already ended
          daysTotal = Math.floor(secTotal / (24 * 60 * 60))
          currentDay = daysTotal
          daysLeft = 0
          percentage = 100
        } else if (secPassed > secTotal) { // not started
          daysTotal = Math.floor(secTotal / (24 * 60 * 60))
          currentDay = -1
          daysLeft = 0
          percentage = 0
        } else { // growing now
          daysTotal = Math.floor(secTotal / (24 * 60 * 60))
          currentDay = Math.floor(secPassed / (24 * 60 * 60))
          daysLeft = daysTotal - currentDay
          percentage = Math.floor(secPassed / secTotal * 100)
        }
      }
      this.growingProgress = {
        daysTotal: daysTotal < 0 ? 0 : daysTotal,
        currentDay: currentDay,
        daysLeft: daysLeft,
        percent: percentage,
      }
    },
    getValidRole () {
      return this.$store.getters.get_user_roles.includes('AGRONOMIST')
    },
    cancelModal () {
      this.$refs.modal.cancel()
    },
    onChangeRecipe () {
      this.$emit('onChangeRecipe', {
        productionGrowingRecipe: this.productionGrowingRecipe,
        controlGroupId: this.getControlGroupId(),
        controlAreaId: this.getAreaId(),
      })
    },
  },
  components: {
    AtRecipeTimeline,
  },
}
</script>

<style lang="scss">
.content__recipe {
  display: flex;
  min-height: 7rem;
  align-items: center;

  &__icon {
    font-size: pxtorem(30);
    display: flex;
    justify-content: flex-end;
    padding-top: 1.5rem;
    padding-right: 1rem;

    i {
      color: hsl(var(--primary-color-hue), 30%, 70%);
      font-size: pxtorem(46);
      margin-right: 0;
    }
  }

  &__details {
    display: flex;
    text-align: center;
    min-height: 7.05rem;

    &__titles {
      display: inline-block;
      align-self: end;
    }

    &__title {
      font-size: pxtorem(22);
      color: var(--primary-color);
    }

    &__subtitle {
      font-size: pxtorem(15);
      color: hsl(var(--primary-color-hue), 40%, 30%);
      padding-top: 0.5rem;
    }
  }

  &__buttons {
    display: flex;
    text-align: center;
    justify-content: center;
    height: 6.5rem;

    &__content {
      display: inline-block;
      align-self: end;

      &__buttons {
        font-size: pxtorem(24);

        .va-button {
          padding-left: 0.5rem;
          padding-right: 0.5rem;

          &__content__title,
          &__content i {
            color: hsl(var(--primary-color-hue), 40%, 30%);
          }
        }
      }
    }
  }
}

.va-card-table.recipes-card .va-card__body {
  padding: 0;
  overflow-y: hidden;
}

.va-modal__overlay.recipes-modal {
  .va-modal.va-modal--size-large {
    width: 90vw;
    max-width: 90vw;

    .va-modal__inner {
      max-width: 100%;
      padding: 0;

      .title {
        margin-left: 1.25rem;
        font-size: 1rem;
      }
    }

    .va-modal__header {
      text-align: end;
      margin-right: 1.25rem;
    }

    .va-modal__message {
      width: 100%;
    }
  }
}
</style>
